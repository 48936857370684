@use '@angular/material' as mat;
@use 'theme' as theme;

@include mat.tabs-theme(theme.$theme);
@include mat.table-theme(theme.$theme);
@include mat.radio-theme(theme.$theme);
@include mat.progress-bar-theme(theme.$theme);
@include mat.slide-toggle-theme(theme.$theme);
@include mat.button-theme(theme.$theme);
@include mat.paginator-theme(theme.$theme);
@include mat.snack-bar-theme(theme.$theme);
@include mat.dialog-theme(theme.$theme);
@include mat.fab-theme(theme.$theme);
@include mat.icon-button-theme(theme.$theme);
@include mat.icon-theme(theme.$theme);
@include mat.checkbox-theme(theme.$theme);
@include mat.list-theme(theme.$theme);
@include mat.card-theme(theme.$theme);
@include mat.progress-spinner-theme(theme.$theme);
@include mat.autocomplete-theme(theme.$theme);
@include mat.form-field-theme(theme.$theme);
@include mat.select-theme(theme.$theme);
@include mat.input-theme(theme.$theme);
@include mat.option-theme(theme.$theme);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: mat.get-color-from-palette(theme.$blue-grey, 300);
  }

  ::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 5%;
  }

  ::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 5%;
  }
}

:root {
  .list-management {
    display: grid;
    grid-template-rows: min-content 1fr;

    .filter-container {
      padding: 10px 20px;
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 1;
    }

    .list-header {
      padding: 20px 50px;
      position: sticky;
      top: 98px;
      background-color: white;
    }

    .list-body {
      padding: 20px 50px;
    }

    .list-row {
      display: grid;
      border-radius: 3px;
      grid-template-columns: repeat(4, 1fr);
      padding: 15px;
      font-size: 13px;

      .list-col {
        text-align: center;
      }
    }

    .clip-text {
      width: 300px;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .clickable {
      cursor: pointer;
    }

    .blue-bg {
      margin-bottom: 7px;
      background-color: #b2d6f2;
    }

    .light-blue-bg {
      margin-bottom: 7px;
      background-color: #e5f1fb;
    }
  }

  .list-management-filter {
    width: 100%;
    height: 100%;

    .filter {
      display: grid;
      font-size: 13px;
      row-gap: 20px;
      column-gap: 60px;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

      mat-form-field::ng-deep {
        margin-right: 25px;

        * {
          color: #000;
        }

        .mat-form-field-underline {
          background-color: #000;
          height: 1px;
        }
      }
    }
  }

  .management-forms {
    height: 100%;
    display: grid;
    place-items: center;

    .content {
      font-size: 13px;

      .forms-fields {
        display: flex;
        flex-direction: column;
      }

      mat-checkbox {
        margin-left: 30px;
      }

      mat-form-field {
        width: 300px;
        margin-left: 30px;
      }

      .accessform-row {
        border: 1px solid mat.get-color-from-palette(theme.$primary);
        margin-bottom: 10px;
        padding-top: 5px;
      }

      .invisible {
        visibility: hidden;
      }

      .accessform-row:first-of-type {
        margin-top: 15px;
      }

      .actions {
        margin-top: 60px;
        display: flex;
        justify-content: center;
      }

      .add-btn {
        float: right;
      }

      .blue-text {
        font-size: 20px;
        color: mat.get-color-from-palette(theme.$primary);
      }

      .bg-blue {
        height: 1px;
        border: none;
        background-color: mat.get-color-from-palette(theme.$primary);
      }

      .blue-btn {
        background-color: mat.get-color-from-palette(theme.$primary);
        color: white;
        margin-left: 20px;
      }

      .blue-btn:disabled {
        background-color: lighten(
          mat.get-color-from-palette(theme.$primary),
          40
        );
      }
    }

    .help-container {
      background-color: mat.get-color-from-palette(theme.$primary);
      color: white;
      position: fixed;
      font-size: 13px;
      padding: 15px;
      top: 90px;
      right: 30px;
    }
  }

  .floating-button {
    position: fixed;
    bottom: 60px;
    right: 90px;
    z-index: 1000;
  }

  .uppercase {
    text-transform: uppercase;
  }

  input:read-only {
    color: #888;
  }

  .readonly-wrapper {
    display: none;
  }

  [ng-reflect-panel-class='readonly-wrapper'] .mat-select-value {
    color: #888;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }

  .mat-mdc-select-panel {
    min-width: min-content;
    .mat-mdc-option .mdc-list-item__primary-text {
      white-space: nowrap;
    }
  }
}
